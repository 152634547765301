import './components/product';
import './components/carrusel-elements';
//import './components/pagination-scroll';
import './components/modules/facetedsearch';
import './components/modules/mainmenu';

$(document).ready(function() {
	/**
	 * BOTÓN SCROLL QUE APARECE CUANDO SE HACE SCROLL HACIA ABAJO EN UNA WEB.
	 * SU FUNCIÓN ES QUE AL HACER CLIC EN EL ÉL SUBA LENTAMENTE A LA CABECERA.
	 * EVITANDO HACER SCROLL CON EL RATÓN
	 */
	
	if ($('#alc_scrollup').length > 0) {
		let alc_footer_height = $('#footer').height();
		$('#alc_scrollup').css('bottom', alc_footer_height+10);
	}

	$(document).on({
		click: function(e) {
			e.preventDefault();
			$("html, body").animate({ scrollTop: 0 }, 600);
			return false;
		}
	}, '#alc_scrollup');

	$(window).scroll(function() {
	  	if ($(this).scrollTop() > 500) {
	   		$('#alc_scrollup').fadeIn();
	  	} else {
	  		$('#alc_scrollup').fadeOut();
	  	}
	});

	$(window).resize(function(){
		if ($('#alc_scrollup').length > 0) {
			let alc_footer_height = $('#footer').height();
			$('#alc_scrollup').css('bottom', alc_footer_height+10);
		}
	});

	/**
	 * BOTON COMPRAR
	 * REDIRIGIR DIRECTAMENTE A COMPRAR AL HACER CLIC
	 */
	$(document).on({
        click: function(e) {
			e.preventDefault();
			let alc_params = $('.product-actions').find("form:first").serialize();
			let alc_href = $('#add-to-cart-or-refresh').attr('action')+'?'+alc_params;
			console.log(alc_href);

			$.ajax({
				method: "POST",
				url: alc_href,
				data: {
					"add":"1",
            		"action":"update"
				},
				dataType: "json"
			}).done(function( jsonData ) {
				if (jsonData.hasErrors) {
					displayErrors(jsonData.errors);
				} else {
					window.location.replace(prestashop.urls.pages.cart+'?action=show');
				}
			});

            return false;
        }
    }, '.buy-to-cart');

    prestashop.on('updatedProduct',function() {
        if (typeof prestashop.page.page_name !== 'undefined' && prestashop.page.page_name == 'product' && typeof $('.add-to-cart') !== 'undefined') {
            if ($('.add-to-cart').is(':disabled')) {
                $('.buy-to-cart').attr('disabled', 'disabled');
            } else {
                $('.buy-to-cart').removeAttr('disabled');
            }
        }

        if (typeof prestashop.page.page_name !== 'undefined' && prestashop.page.page_name == 'product') {
            let alc_product_reference_header = $('.product-reference span').html();
            $('.product-reference-header span').html(alc_product_reference_header);
        }
	});
});



$(document).ready(function() {
    /**
     * MOSTRAR FILTROS
     * GUARDAR EN UNA COOKIE PARA QUE EL DESPLEGABLE DE FILTROS NO DESAPAREZCA CUANDO HAYAMOS SELECCIONADO ALGÚN FILTRO
     */
    $(document).on({
        click: function(e){
            e.preventDefault();
            let alc_fvalue = 'filterOpen';
            if ($('#left-column').is(':visible')) {
                alc_fvalue = 'filterClose';
            }
            $('#left-column').toggle('slide', { direction: 'left' }, 500);
            $.ajax({
                url: prestashop.urls.base_url+'/themes/'+prestashop.urls.theme_assets.split('/')[2]+'/ajax-call.php',
                type: 'POST',
                data: {
                    type: alc_fvalue
                },
                success: function (response) {
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    console.log(textStatus, errorThrown);
                }
            });
        }
    }, ".alc_button_show_filters");

    /**  
     * OCULTAR FILTROS
     * ELIMINAR LA COOKIE PARA QUE EL DESPLEGABLE DE FILTROS DESAPAREZCA AUNQUE LA PÁGINA RECARGUE
     */
    $(document).on({
        click: function(e){
            e.preventDefault();
            $('#left-column').toggle('slide', { direction: 'left' }, 500);
            $.ajax({
                url: prestashop.urls.base_url+'/themes/'+prestashop.urls.theme_assets.split('/')[2]+'/ajax-call.php',
                type: 'POST',
                data: {
                    type: 'filterClose'
                },
                success: function (response) {
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    console.log(textStatus, errorThrown);
                }
            });
        }
    }, "#alc_close_filters button");
});

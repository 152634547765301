$(document).ready(function() {
    /**
     * EFECTO ICONO MENÚ
     * AÑADE UNA CLASE open QUE EN CSS REALIZA EL EFECTO DE TRANSICIÓN
     */
    $(document).on({
        click: function(e){
            $(this).toggleClass('open');
        }
    }, "#menu-icon");

    /**
     * MENU 
     * MOSTRAR BUSCADOR CUANDO HAGO CLICK Y OCULTAR CUANDO CIERRO EL MENU
     */
    $(document).on({
        click: function(e){
            e.preventDefault();
            if (!$('#displayTop').is(':visible')) {
                $('body').addClass('menuopen');
            } else {
                $('body').removeClass('menuopen');
            }
            if ($(window).width() < 768) {
                $('.search-widget').toggle();
            } else {
               // $('#mobile_top_menu_wrapper').slideToggle();
               $('#displayTop').slideToggle();
               
            }

        }
    }, '#menu-icon');
});

$(document).ready(function() {
    /**
     * EVENTO PASAR POR ENCIMA DE MINIATURAS DENTRO DE
     * LA FICHA DEL PRODUCTO
     */

    /*
    $(document).on({
        mouseover: function () {
            let alc_src = $(this).attr('data-image-large-src');
            $('.js-thumb').removeClass('selected');
            $('.js-modal-thumb').removeClass('selected');
            $(this).addClass('selected');
            $('.js-qv-product-cover').attr('src', alc_src);
            $('.js-modal-product-cover').attr('src', alc_src);
        }
    }, "#product ul.product-images li img");
    */

    /**
     * EVENTO PASAR POR ENCIMA DE MINIATURAS Y CLICK
     * DENTRO DE LA FICHA DEL PRODUCTO CUANDO DESPLIEGAS
     * EL MODAL DE LA IMAGEN
     */
    $(document).on({
        click: function(e){
            e.preventDefault();
            let alc_src = $(this).parent().find('img').attr('data-image-large-src');
            $('.js-thumb').removeClass('selected');
            $('.js-modal-thumb').removeClass('selected');
            $(this).parent().find('img').addClass('selected');
            $('.js-qv-product-cover').attr('src', alc_src);
            $('.js-modal-product-cover').attr('src', alc_src);
            $('a.thumb-change-image').removeClass('selected');
            $(this).addClass('selected');
        },
        /*mouseover: function(){
            var alc_src = $(this).parent().find('img').attr('data-image-large-src');
            $('.js-thumb').removeClass('selected');
            $('.js-modal-thumb').removeClass('selected');
            $(this).parent().find('img').addClass('selected');
            $('.js-qv-product-cover').attr('src', alc_src);
            $('.js-modal-product-cover').attr('src', alc_src);
            $('a.thumb-change-image').removeClass('selected');
            $(this).addClass('selected');
        }*/
    }, "#product ul.product-images li a.thumb-change-image");

    /**
     * EVENTO SCROLL IZQUIERDA DERECHA EN IMAGEN DE PRODUCTO
     * SU FUNCIÓN ES CAMBIAR LA IMAGEN DEL PRODUCTO AL ARRASTRAR DE DERECHA A IZQUIERDA EN VERSIÓN MOVIL
     * PERMITE TAMBIÉN MOVIMIENTOS ARRIBA Y ABAJO. SE DEBEN AÑADIR LAS CONDICIONES DENTRO DE processingRoutine() PARA HABILITAR
     */

    let alc_triggerElementID = null; // Esta variable es utilizada para identificar el elemento lanzado
    let alc_fingerCount = 0;
    let alc_startX = 0;
    let alc_startY = 0;
    let alc_curX = 0;
    let alc_curY = 0;
    let alc_deltaX = 0;
    let alc_deltaY = 0;
    let alc_horzDiff = 0;
    let alc_vertDiff = 0;
    let alc_minLength = 72; // la mínima distancia de desplazamiento que debe hacer el usuario para activar el evento
    let alc_swipeLength = 0;
    let alc_swipeAngle = null;
    let alc_swipeDirection = null;

    let alc_touchStart = function (event,passedName) {
        // disable the standard ability to select the touched object
        // event.preventDefault();
        // get the total number of fingers touching the screen
        alc_fingerCount = event.originalEvent.touches.length;
        // since we're looking for a swipe (single finger) and not a gesture (multiple fingers),
        // check that only one finger was used
        if (alc_fingerCount == 1) {
            // get the coordinates of the touch
            alc_startX = event.originalEvent.touches[0].pageX;
            alc_startY = event.originalEvent.touches[0].pageY;
            // store the triggering element ID
            alc_triggerElementID = passedName;
        } else {
            // more than one finger touched so cancel
            alc_touchCancel(event);
        }
    }

    let alc_touchMove = function(event) {
        event.preventDefault();
        if (alc_fingerCount == 1) {
            alc_curX = event.originalEvent.touches[0].pageX;
            alc_curY = event.originalEvent.touches[0].pageY;
        } else {
            alc_touchCancel(event);
        }
    }

    let alc_touchEnd = function (event) {
        // event.preventDefault();
        // verifique si se usó más de una pulsación (dedo) y si hay una coordenada final
        if (alc_fingerCount == 1 && alc_curX != 0) {
            // usa la Fórmula de Distancia para determinar la longitud del golpe
            alc_swipeLength = Math.round(Math.sqrt(Math.pow(alc_curX - alc_startX,2) + Math.pow(alc_curY - alc_startY,2)));
            // Si el usuario pasó más de la longitud mínima, realice la acción apropiada
            if (alc_swipeLength >= alc_minLength) {
                alc_caluculateAngle();
                alc_determineSwipeDirection();
                alc_processingRoutine();
                alc_touchCancel(event);
            } else {
                alc_touchCancel(event);
            }
        } else {
            alc_touchCancel(event);
        }
    }

    let alc_touchCancel = function (event) {
        // Resetear las variable de vuelta a sus valores por defecto
        alc_fingerCount = 0;
        alc_startX = 0;
        alc_startY = 0;
        alc_curX = 0;
        alc_curY = 0;
        alc_deltaX = 0;
        alc_deltaY = 0;
        alc_horzDiff = 0;
        alc_vertDiff = 0;
        alc_swipeLength = 0;
        alc_swipeAngle = null;
        alc_swipeDirection = null;
        alc_triggerElementID = null;
    }

    let alc_caluculateAngle = function () {
        let alc_X = alc_startX-alc_curX;
        alc_deltaX = alc_X;
        let alc_Y = alc_curY-alc_startY;
        let alc_Z = Math.round(Math.sqrt(Math.pow(alc_X,2)+Math.pow(alc_Y,2))); //la distancia - redondeada - en pixeles
        let alc_r = Math.atan2(alc_Y,alc_X); //Ángulo en radianes (Sistema cartesiano)
        alc_swipeAngle = Math.round(alc_r*180/Math.PI); //Ángulo en grados
        if (alc_swipeAngle < 0) {
            alc_swipeAngle = 360 - Math.abs(alc_swipeAngle);
        }
    }

    let alc_determineSwipeDirection = function () {
        if ((alc_swipeAngle <= 45) && (alc_swipeAngle >= 0)) {
            alc_swipeDirection = 'left';
        } else if ((alc_swipeAngle <= 360) && (alc_swipeAngle >= 315)) {
            alc_swipeDirection = 'left';
        } else if ((alc_swipeAngle >= 135) && (alc_swipeAngle <= 225)) {
            alc_swipeDirection = 'right';
        } else if ((alc_swipeAngle > 45) && (alc_swipeAngle < 135)) {
            alc_swipeDirection = 'down';
        } else {
            alc_swipeDirection = 'up';
        }
    }

    let alc_moveLeft = function (sElement) {
        let alc_element = '#product ul.product-images li';
        if (typeof $(alc_element+' a.selected') !== 'undefined' && $(alc_element+' a.selected').length == 1) {
            let alc_elementnext = $(alc_element+' a.selected').parent().next();
            if (typeof alc_elementnext !== 'undefined' && alc_elementnext.length == 1) {
                $(alc_element+' a.selected').removeClass('selected');
                alc_elementnext.find('a').addClass('selected');
                let alc_url = alc_elementnext.find('img').attr('data-image-large-src');
                sElement.attr('src', alc_url);
            }
        }
    }

    let alc_moveRight = function (sElement) {
        let alc_element = '#product ul.product-images li';
        if (typeof $(alc_element+' a.selected') !== 'undefined' && $(alc_element+' a.selected').length == 1) {
            let alc_elementprevious = $(alc_element+' a.selected').parent().prev();
            if (typeof alc_elementprevious !== 'undefined' && alc_elementprevious.length == 1) {
                $(alc_element+' a.selected').removeClass('selected');
                alc_elementprevious.find('a').addClass('selected');
                var alc_url = alc_elementprevious.find('img').attr('data-image-large-src');
                sElement.attr('src', alc_url);
            }
        }
    }

    let alc_processingRoutine = function () {
        if (alc_swipeDirection == 'left') {
            alc_moveLeft(alc_triggerElementID);
        } else if (alc_swipeDirection == 'right') {
            alc_moveRight(alc_triggerElementID);
        }
    }

    $(document).on({
        touchstart: function(e) {
            alc_touchStart(e,$(this));
        },
        touchend: function(e) {
            alc_touchEnd(e);
        },
        touchmove: function(e) {
            alc_touchMove(e);
        }
    }, "#product .js-qv-product-cover");

    /**
     * EVENTO DESPLEGABLE EN COMPARTIR EN REDES SOCIALES
     * DENTRO DE LA FICHA DEL PRODUCTO
     */
    $(document).on({
        click: function(e){
            e.preventDefault();
            $(this).parent().find('ul').toggle('slide', 1100);
        }
    }, "#social-sharing-open");
});

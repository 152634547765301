$(document).ready(function() {
    /**
     *  CARRUSEL DE IMAGENES EN PRODUCTO
     */
    if (typeof $('body#product ul.js-qv-product-images') !== 'undefined') {
        let alc_carrusel_images_product = {
            init: function () {
                let alc_itemsforslide_productimages = parseInt($('body#product ul.js-qv-product-images li').length);
                if (alc_itemsforslide_productimages > 4) {
                    if (!$('body#product ul.js-qv-product-images').hasClass('slick-initialized')) {
                        $('body#product ul.js-qv-product-images').slick({
                            dots: false,
                            infinite: false,
                            arrows: true,
                            speed: 300,
                            draggable: true,
                            slidesToShow: 4,
                            slidesToScroll: 1,
                            vertical: true,
                            verticalScrolling: true,
                            touchThreshold: 100,
                            nextArrow: '<button type="button" class="slick-next"></button>',
                            prevArrow: '<button type="button" class="slick-prev"></button>',
                            responsive: [
                                {
                                    breakpoint: 768,
                                    settings: 'unslick'
                                }
                            ]
                        });
                    }
                }
            }
        };

        alc_carrusel_images_product.init();

        prestashop.on('updatedProduct',function() {
            if (typeof $('body#product ul.js-qv-product-images') !== 'undefined') {
                alc_carrusel_images_product.init();
            }
        });

        let alc_carrusel_modal_images_product = {
            init: function () {
                let alc_itemsforslide_productimages = parseInt($('body#product ul.js-qv-product-images li').length);
                if (alc_itemsforslide_productimages > 3) {
                    if (!$('body#product ul.js-modal-product-images').hasClass('slick-initialized')) {
                        $('body#product ul.js-modal-product-images').slick({
                            dots: false,
                            infinite: false,
                            arrows: true,
                            speed: 300,
                            draggable: true,
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            vertical: true,
                            verticalScrolling: true,
                            touchThreshold: 100,
                            nextArrow: '<button type="button" class="slick-next"></button>',
                            prevArrow: '<button type="button" class="slick-prev"></button>',
                            responsive: [
                                {
                                    breakpoint: 992,
                                    settings: 'unslick'
                                }
                            ]
                        });
                    }
                }
            }
        };

        $(document).on({
            click: function(e) {
                if ($('#product-modal').show()) {
                    alc_carrusel_modal_images_product.init();
                }
            }
        }, '.product-cover');
    }

    /**
     *  CARRUSEL DE ACCESORIOS/RELACIONADOS EN PRODUCTO
     */
    if (typeof $('.product-accessories .products') !== 'undefined') {
        let alc_itemsforslide_accesories = parseInt($('.product-accessories .products article').length);
        if (alc_itemsforslide_accesories > 4) {
            let alc_carrusel_accesories_product = {
                init: function () {
                    $('.product-accessories .products').slick({
                        dots: false,
                        infinite: false,
                        arrows: true,
                        speed: 300,
                        draggable: true,
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        nextArrow: '<button type="button" class="slick-next"></button>',
                        prevArrow: '<button type="button" class="slick-prev"></button>',
                        responsive: [
                            {
                                breakpoint: 1295,
                                settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 2,
                                    dots: true,
                                    arrows: false,
                                }
                            }
                        ]
                    });
                }
            };

            alc_carrusel_accesories_product.init();
        }
    }
});
